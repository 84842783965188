// i18next-extract-mark-ns-start products-engdyn-block

import { ENGDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductEngdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							ENGDYN provides all loads required for concise
							powertrain structure analysis. With its automated model
							preparation features and integrated finite element (FE)
							solver, ENGDYN has all the tools needed to build fully
							3D systems within one environment. Also included is an
							extensive array of FE analysis (FEA) interface tools
							that fully automate the process of applying the ENGDYN
							results as boundary conditions to further FEA structural
							analyses. These can be set up for all major FEA solvers
							or, alternatively, ENGDYN can be combined with Realis
							Simulation’s own FEA environment, FEARCE, to complete
							the entire process right through to durability analysis.
						</p>
						<h2>Specific cylinder-block applications</h2>
						<ul>
							<li>Crankcase durability</li>
							<li>
								Thermo-mechanical simulation of powertrain structure
							</li>
						</ul>
						<h2>Key features</h2>
						<ul>
							<li>Automated preparation tools</li>
							<li>Integrated FE solver for model reduction&nbsp;</li>
							<li>Internal solver</li>
							<li>Quasi-static time domain solutions</li>
							<li>
								Frequency and time-domain nodal vibration stress
								analyses
							</li>
							<li>
								Automatic loading of the FEA model for major
								commercial solvers
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{ENGDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductEngdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-engdyn-block", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/engdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;
